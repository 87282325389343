<template>
  <Transition name="fade-in">
    <div v-if="isOpen" class="form-confirmation">
      <AppIcon
        icon="close-box"
        :is-raw="true"
        @click="reject()"
      />

      <AtomHeading
        tag="h3"
        appereance="h2"
        :text="data.title"
      />

      <AtomRichtext :data="data" />

      <AtomButton
        :text="data.button_text"
        @click="confirm()"
      />
    </div>
  </Transition>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    isOpen: {
        type: Boolean,
        default: false,
    },
});

const {
    confirm,
    reject,
} = useFormConfirmation();
</script>

<style lang="scss" scoped>
.form-confirmation {
    @include z-index('confirmationModal');
    @include fluid('padding-left', 30px, 92px);
    @include fluid('padding-right', 30px, 92px);
    @include fluid('padding-bottom', 70px, 92px);
    @include fluid('padding-top', 70px, 92px);

    position: fixed;
    top: 50%;
    left: 50%;
    width: 500px;
    width: calc(100vw - var(--page-padding) - var(--page-padding));
    max-width: 1194px;

    border-radius: 12px;
    margin: auto;
    background: $C_WHITE;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%) translateY(-50%);

    .app-icon {
        position: absolute;
        top: 14px;
        right: 15px;
        width: 46px;
        cursor: pointer;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: scale(1.04);
        }
    }

    .atom-button {
        @include fluid('margin-top', 15px, 25px);
    }
}
</style>

const _resolvePromise = ref(null);
const isConfirmationOpen = ref(false);

export default () => {
    const alertConfirmation = () => new Promise((resolve) => {
        _resolvePromise.value = resolve;
        isConfirmationOpen.value = true;
    });

    const confirm = () => {
        isConfirmationOpen.value = false;
        _resolvePromise.value(true);
    };

    const reject = () => {
        isConfirmationOpen.value = false;
        _resolvePromise.value(false);
    };

    return {
        alertConfirmation,
        confirm,
        reject,
        isConfirmationOpen,
    };
};
